import actions from './actions';

const initState = {
  user: null,
  loginSuccess: false,
  token: null,
  sections: [],
  errorMsg: '',
  errData: [],
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        user: action.user,
        token: action.token,
        sections: action.sections,
        loginSuccess: true,
        errorMsg: '',
        errData: [],
      };
    case actions.LOGIN_ERROR:
      return {
        ...state,
        loginSuccess: false,
        errorMsg: action.msg,
        errData: action.data,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}
