export const loadState = () => {
  try {
    const savedState = window.localStorage.getItem('state');
    if (savedState === null) {
      return undefined;
    }
    return JSON.parse(savedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    try {
      const stringStore = JSON.stringify(state);
      window.localStorage.setItem('state', stringStore);
    } catch (e) {
      alert('Error while parsing json');
    }
  } catch (err) {
    // throw error
    throw err;
  }
};
