export default {
  siteName: 'Rwanga Foundation',
  siteIcon: 'ion-flash',
  footerText: `Lucid Source @ ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  API_LINK: 'https://api.rwanga.org',
  // API_LINK: 'http://127.0.0.1:3000',
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
};
