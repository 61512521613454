import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import axios from 'axios';
import { getToken, clearToken } from '@iso/lib/helpers/utility';
import actions from './actions';
import siteConfig from './../../config/site.config';

const headerOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};
const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const { username, password } = payload;
    try {
      const res = yield call(
        payload =>
          axios.post(
            `${siteConfig.API_LINK}/auth/login`,
            payload,
            headerOptions
          ),
        payload
      );
      if (res.status === 200 && res.data.success == true) {
        yield put({
          type: actions.LOGIN_SUCCESS,
          user: res.data.data.user,
          token: res.data.data.token,
          sections: res.data.data.sections,
        });
      } else if (res.status === 200) {
        yield put({
          type: actions.LOGIN_ERROR,
          msg: res.data.msg,
          data: [],
        });
      }
    } catch (e) {
      const errors = e.response.data.errors;
      yield put({
        type: actions.LOGIN_ERROR,
        msg: 'server responded with error',
        data: errors,
      });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {});
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*({ payload }) {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield clearToken();
    history.push('/');
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getToken().get('idToken');
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: 'Profile',
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
